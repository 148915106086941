body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: var(--background);
  color: var(--text);
  font-family: var(--body);
  text-align: center;
  line-height: var(--compact);
}

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.733);
  margin-top: -1px;
  animation: zoom 0.3s ease-in-out;
}

@keyframes zoom {
  from {
      transform: scale(0);
  }
  to {
      transform: scale(1);
  }
}
.modal img {
  width: 50%;
  object-fit: cover;
}

.closeBtn {
  color: rgba(255, 255, 255, 0.87);
  font-size: 25px;
  position: absolute;
  top: 10%;
  right: 10%;
  margin: 20px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.closeBtn:hover {
  color: rgb(255, 255, 255);
}


.main-modal {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px 60px 0px;
}

@media (max-width: 950px) {
  .closeBtn  {
      top: 25%;
      right: 13%;
  }
}

@media (max-width: 550px) {
  .closeBtn  {
    top: 25%;
    right: 13%;
}
}